<template>
  <div>
    <h1 class="text-center">{{tenantName}}</h1>
    <b-nav type="dark">
      <b-nav-item v-for="navItem in topNav" v-bind:key="navItem.target" v-model="topNav"
                  :to="{name: navItem.type.toLowerCase(), params: {id: navItem.target}}">
        {{ navItem.name }}
      </b-nav-item>
    </b-nav>

    <div v-for="discount in activeDiscounts" v-bind:key="discount.uuid" class="text-center mb-5 mt-5">
      <div id="discount-title">{{ discount.text }}</div>
      <div id="discount-description">
        {{ discount.value }} {{ discount.type === 'PERCENT' ? '%' : '€' }} auf {{ discount.mealTypes.map(mealType => translations[mealType]).join(" und ") }} bei {{ discount.orderTypes.map(orderType => translations[orderType]).join(" oder ") }}
      </div>
      <div id="discount-date">{{ discount.start | formatDate }} bis {{ discount.end | formatDate }}</div>
    </div>

    <OpeningHoursComponent/>
  </div>
</template>

<style>
#discount-title {
  color: red;
  font-weight: bold;
  font-size: 1.5em;
}
#discount-description {
  color: red;
  font-size: 1.1em;

}
#discount-date {
  font-size: 0.75em;
}
</style>

<script>
import OpeningHoursComponent from "@/components/OpeningHoursComponent";

export default {
  name: 'Home',
  title: 'Home',
  components: {OpeningHoursComponent},
  data() {
    return {
      topNav: this.topNav,
      activeDiscounts: [],
      translations: {
        'PICK_UP': 'Abholung',
        'DELIVERY': 'Lieferung',
        'DISH': 'Speisen',
        'BEVERAGE': 'Getränke',
        'OTHER': 'anderes'
      }
    }
  },
  beforeCreate() {
    if (window.$tenant.homePageSlug) {
      this.$router.push(window.$tenant.homePageSlug);
      return;
    }
    this.topNav = [];
    this.bottomNav = [];

    this.$http.get('/api/navigation')
        .then(response => {
          this.topNav = response.data.navigation.TOP;
        });

    this.$http.get('/api/discount/active')
        .then(response => {
          this.activeDiscounts = response.data;
        });

    this.$loading = false;
  },
  computed: {
    tenantName() {
      return window.$tenant.name;
    }
  }
}
</script>
