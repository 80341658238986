<template>
  <b-row class="mt-5">
    <b-card title="Einloggen" sub-title="Willkommen zurück!" class="ml-auto mr-auto">
      <b-alert variant="danger" v-model="error" dismissible>Ungültige Login-Daten!</b-alert>
      <b-form @submit.prevent="login">
        <b-form-group>
          <b-form-input type="email" required placeholder="E-Mail" v-model="username"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input type="password" required placeholder="Passwort" v-model="password"></b-form-input>
        </b-form-group>
        <!--<b-button type="submit" variant="outline-dark" class="mr-5 float-left">Passwort vergessen?</b-button>-->
       <b-button type="submit" variant="outline-success" class="float-right">Login</b-button>
      </b-form>
    </b-card>
  </b-row>
</template>

<style>

</style>

<script>
export default {
  name: 'Login',
  title: 'Login',
  data() {
    return {
      username: '',
      password: '',
      error: false
    }
  },
  created() {
    if (this.$store.getters.loggedIn) {
      this.$router.push('/');
    }
    this.$loading = false;
  },
  methods: {
    login() {
      this.$http.post('/api/user/authenticate', {
        username: this.username,
        password: this.password
      }).then(response => {
        this.$store.dispatch('login', {token: response.data.token, user: response.data.user});
        this.$router.push('/');
      }).catch(error => {
        if (error.response.status === 401) { // 401 Unauthenticated
          this.error = true;
        }
      });

    }
  }
}
</script>
