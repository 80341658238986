<template>
  <div>
    <h1>Persönliche Informationen</h1>
    <hr>
    <b-form @submit.prevent="changeProfileData">
      <b-form-group label="E-Mail Adresse" description="Die E-Mail Adresse wird zum Einloggen benötigt.">
        <b-form-input type="email" placeholder="E-Mail Adresse" required v-model="profile.username"></b-form-input>
      </b-form-group>

      <b-form-group label="Vorname">
        <b-form-input placeholder="Vorname" required v-model="profile.firstname"></b-form-input>
      </b-form-group>

      <b-form-group label="Nachname">
        <b-form-input placeholder="Nachname" required v-model="profile.lastname"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="outline-primary" class="float-right">Speichern</b-button>
    </b-form>

    <h1>Benachrichtigungen</h1>
    <b-form-checkbox switch v-model="notificationsEnabled" @change="handleNotificationUpdate">Benachrichtigungen aktivieren</b-form-checkbox>


    <h1 class="mt-5">Passwort ändern</h1>
    <b-form @submit.prevent="changePassword">
      <b-form-group label="altes Passwort">
        <b-form-input type="password" placeholder="altes Passwort" required
                      v-model="password.oldPassword"></b-form-input>
      </b-form-group>

      <b-form-group label="neues Passwort">
        <b-form-input type="password" placeholder="neues Passwort" required
                      v-model="password.newPassword"></b-form-input>
      </b-form-group>

      <b-form-group label="neues Passwort bestätigen">
        <b-form-input type="password" placeholder="neues Passwort bestätigen" required
                      v-model="password.newPasswordConfirm"></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="outline-primary" class="float-right">Passwort ändern</b-button>
    </b-form>

    <h1 class="mt-5">Gespeicherte Adressen
      <b-button class="ml-2" variant="outline-success" @click="createAddress">Neu</b-button>
    </h1>
    <hr>

    <b-card-group deck class="mt-4" v-bind:key="chunk.uuid" v-for="chunk in chunk(addresses, 3)">
      <b-card v-bind:key="address.uuid" v-for="address in chunk">
        <b-card-text>
          {{ address.companyName }} <br v-if="address.companyName"/>
          {{ address.firstname }} {{ address.lastname }} <br/>
          <br v-if="!address.companyName"/>
          {{ address.street }} {{ address.houseNr }} <br/>
          {{ address.zip }} {{ address.city }}
        </b-card-text>
        <b-card-footer>
          <b-button type="submit" variant="outline-primary" @click="editAddress(address)">Bearbeiten</b-button>
          <b-button type="submit" variant="outline-danger" class="float-right" @click="deleteAddress(address)">Löschen
          </b-button>
        </b-card-footer>
      </b-card>
    </b-card-group>

    <EditAddressComponent id="editAddressModal" ref="editAddressModal"/>
    <ConfirmComponent id="deleteAddressConfirmModal" ref="deleteAddressConfirmModal"/>
  </div>
</template>

<script>
import EditAddressComponent from "@/components/EditAddressComponent";
import ConfirmComponent from "@/components/ConfirmComponent";

export default {
  components: {ConfirmComponent, EditAddressComponent},
  title: 'Profil',
  async created() {
    if (!this.$store.getters.loggedIn) {
      await this.$router.push('/');
      return;
    }

    await this.loadAddresses();

    this.$loading = false;
  },
  data() {
    return {
      profile: {
        firstname: this.$store.getters.user.firstname,
        lastname: this.$store.getters.user.lastname,
        username: this.$store.getters.user.username
      },
      password: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      },
      addresses: [],
      notificationsEnabled: this.$store.getters.notificationConfig.endpoint !== ''
    };

  },
  methods: {
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    encodeByteArrayToUrlBase64(byteArray) {
      const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(byteArray)));
      return base64.replace(/={1,2}$/, '')
          .replace(/\+/g, '-')
          .replace(/\//g, '_');
    },
    async handleNotificationUpdate(enabled) {
      let disable = false;
      if (enabled) {
        let permission = await Notification.requestPermission();

        if (permission === "granted") {
          let serviceWorkerRegistration = await navigator.serviceWorker.register('service-worker.js');
          let pushSubscription = await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(window.$tenant.publicKey)
          });
          let subscriptionConfig = {
            endpoint: pushSubscription.endpoint,
            key: this.encodeByteArrayToUrlBase64(pushSubscription.getKey('p256dh')),
            auth: this.encodeByteArrayToUrlBase64(pushSubscription.getKey('auth'))
          };

          await this.$http.put('/api/user/addPushSubscription', subscriptionConfig);

          this.$store.commit('SET_NOTIFICATION_SETTINGS', subscriptionConfig.endpoint, subscriptionConfig.key, subscriptionConfig.auth);
        } else {
          disable = true;
        }
      } else {
        disable = true;
      }

      if (disable) {
        let serviceWorkerRegistration = await navigator.serviceWorker.register('service-worker.js');
        serviceWorkerRegistration.pushManager.getSubscription().then(subscription => {
          if (subscription == null) return;
          subscription.unsubscribe();

          this.$http.delete('/api/user/removePushSubscription', {
            data: {
              endpoint: subscription.endpoint,
              key: this.encodeByteArrayToUrlBase64(subscription.getKey('p256dh')),
              auth: this.encodeByteArrayToUrlBase64(subscription.getKey('auth'))
            }
          });
        });

        this.$store.commit('SET_NOTIFICATION_SETTINGS', '', '', '');
        this.notificationsEnabled = false;
      }
    },
    changeProfileData() {
      this.$http.post('/api/user/profile', {
        firstname: this.profile.firstname,
        lastname: this.profile.lastname,
        username: this.profile.username
      }).then(response => {
        this.$store.commit('SET_USER', response.data);
      }).catch(error => {
        if (error.response.status === 400) {
          this.msg = error.response.data.message;
          this.error = true;
        }
      });
    },
    changePassword() {
      if (this.password.newPassword !== this.password.newPasswordConfirm)
        return;

      this.$http.post('/api/user/changePassword', {
        oldPassword: this.password.oldPassword,
        newPassword: this.password.newPassword
      }).then(() => {
      }).catch(error => {
        if (error.response.status === 400) {
          this.msg = error.response.data.message;
          this.error = true;
        }
      });
    },
    async loadAddresses() {
      let response = await this.$http.get('/api/user/addresses');
      this.addresses = response.data;
    },
    deleteAddress(address) {
      this.$refs.deleteAddressConfirmModal.show(() => {
        this.$http.delete('/api/user/addresses', {data: address}).then(() => this.addresses.splice(this.addresses.indexOf(address), 1));
      });
    },
    createAddress() {
      this.$refs.editAddressModal.setAddress({});
      this.$refs.editAddressModal.show(address => this.addresses.push(address));
    },
    editAddress(address) {
      this.$refs.editAddressModal.setAddress(address);
      this.$refs.editAddressModal.show();
    },
    chunk(arr, chunkSize) {
      if (chunkSize <= 0) throw "Invalid chunk size";
      const R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
      return R;
    }

  }
}
</script>