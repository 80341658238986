<template>
  <div>
    <h1>Bestellung</h1>
    <hr>

    <b-collapse id="type-collapse" class="mt-2" visible>
      <div class="text-center">
        <b-button type="button" v-b-toggle.type-collapse.address-collapse variant="outline-success"
                  @click="type = 'DELIVERY';"
                  :disabled="!isOpen(new Date(), 'DELIVERY')">Lieferung
        </b-button>
        <b-button class="ml-2" type="button" v-b-toggle.type-collapse.name-collapse variant="outline-success"
                  @click="type = 'PICK_UP'; minimumOrderValue = 0; deliveryFee = 0;"
                  :disabled="!isOpen(new Date(), 'PICK_UP')">Abholen
        </b-button>
      </div>
    </b-collapse>


    <b-collapse id="name-collapse" class="mt-2">
      <h2>Kunde</h2>

      <b-form ref="formAddress">
        <b-form-group label="Firmenname">
          <b-form-input type="text" placeholder="Firmenname" v-model="address.companyName" autocomplete="off"></b-form-input>
        </b-form-group>

        <b-form-group label="Telefonnummer">
          <b-form-input type="tel" pattern="0\d{1,3}\d+" :state="validatePhoneNumber(address.phoneNumber)" required placeholder="Telefonnummer" v-model="address.phoneNumber" autocomplete="off"></b-form-input>
        </b-form-group>

        <b-form-group label="Vor- und Nachname">
          <b-row>
            <b-col md="6">
              <b-form-input placeholder="Vorname" required :state="validateNotEmpty(address.firstname)" v-model="address.firstname" autocomplete="off"></b-form-input>
            </b-col>
            <b-col md="6">
              <b-form-input placeholder="Nachname" required :state="validateNotEmpty(address.lastname)"  v-model="address.lastname" autocomplete="off"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-button type="button" v-b-toggle.type-collapse.name-collapse variant="outline-primary">Zurück</b-button>
        <b-button :disabled="!nameCompleted" v-b-toggle.payment-collapse.name-collapse class="float-right" type="button"
                  variant="outline-success">Weiter
        </b-button>
      </b-form>
    </b-collapse>

    <b-collapse id="address-collapse" class="mt-2">
      <h2>Lieferadresse
        <b-button v-b-toggle.saved-addresses variant="outline-primary" v-if="this.$store.getters.loggedIn">gespeicherte
          Adressen
        </b-button>
      </h2>
      <b-collapse id="saved-addresses" class="mt-2" v-if="this.$store.getters.loggedIn">
        <b-card-group deck class="mt-4" v-bind:key="chunk.uuid" v-for="chunk in chunk(addresses, 3)">
          <b-card v-bind:key="address.uuid" v-for="address in chunk">
            <b-card-text>
              {{ address.companyName }} <br v-if="address.companyName"/>
              {{ address.firstname }} {{ address.lastname }} <br/>
              <br v-if="!address.companyName"/>
              {{ address.street }} {{ address.houseNr }} <br/>
              {{ address.city }}
            </b-card-text>
            <b-card-footer>
              <b-button type="submit" variant="outline-primary"
                        @click="applySavedAddress(address); $root.$emit('bv::toggle::collapse', 'saved-addresses')">Übernehmen
              </b-button>
            </b-card-footer>
          </b-card>
        </b-card-group>
      </b-collapse>

      <b-form ref="formAddress">
        <b-form-group label="Firmenname">
          <b-form-input type="text" placeholder="Firmenname" v-model="address.companyName"></b-form-input>
        </b-form-group>

          <!-- TODO ein feld -->
        <b-form-group label="Vor- und Nachname">
          <b-row>
            <b-col md="6">
              <b-form-input placeholder="Vorname" required :state="validateNotEmpty(address.firstname)" v-model="address.firstname" autocomplete="off"></b-form-input>
            </b-col>
            <b-col md="6">
              <b-form-input placeholder="Nachname" required :state="validateNotEmpty(address.lastname)" v-model="address.lastname" autocomplete="off"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group label="Telefonnummer">
          <b-form-input type="tel" pattern="0\d{1,3}\d+" :state="validatePhoneNumber(address.phoneNumber)" required placeholder="Telefonnummer" v-model="address.phoneNumber" autocomplete="off"></b-form-input>
        </b-form-group>

        <b-form-group label="Straße & Hausnummer">
          <b-row>
            <b-col md="8">
              <b-form-input placeholder="Straße" required :state="validateNotEmpty(address.street)" v-model="address.street" autocomplete="off"></b-form-input>
            </b-col>
            <b-col md="4">
              <b-form-input placeholder="Hausnummer" required :state="validateNotEmpty(address.houseNr)" v-model="address.houseNr" autocomplete="off"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group label="Ort">
          <vue-bootstrap-typeahead :min-matching-chars="0" placeholder="Ort" required :state="validateNotEmpty(address.city)" v-model="address.city" :data="availableCities"></vue-bootstrap-typeahead>
        </b-form-group>
        <b-button type="button" v-b-toggle.type-collapse.address-collapse variant="outline-primary">Zurück</b-button>
        <b-button :disabled="!addressCompleted" @click="setDeliveryFee()" v-b-toggle.payment-collapse.address-collapse class="float-right"
                  type="button" variant="outline-success">Weiter
        </b-button>
      </b-form>
    </b-collapse>

    <b-collapse id="payment-collapse" class="mt-2">
      <h2>Bezahlmethode wählen</h2>

      <b-card-group>
      <b-card v-for="[name, config] of Object.entries(paymentMethods).filter(config => config[1].useInFrontend === true).sort()" v-bind:key="name">
        <b-card-text class="text-center">
          <b-button class="ml-auto mr-auto" v-bind:key="name" @click="selectPaymentMethod(name)" v-b-toggle.payment-collapse.overview-collapse variant="outline-primary">
            {{ config.friendlyName }}
            <template v-if="config.surcharge > 0">
                 ({{ config.surcharge }}% Aufschlag)
            </template>
          </b-button>
        </b-card-text>
      </b-card>
      </b-card-group>

      <div class="mt-4">
      <b-button v-if="type === 'PICK_UP'" v-b-toggle.payment-collapse.name-collapse variant="outline-primary">
        Zurück
      </b-button>
      <b-button v-if="type === 'DELIVERY'" v-b-toggle.payment-collapse.address-collapse variant="outline-primary">
        Zurück
      </b-button>
      </div>
    </b-collapse>

    <b-collapse id="overview-collapse" class="mt-2">
      <h2>Übersicht</h2>

      <b-list-group flush class="mb-3">
        <b-list-group-item class="flex-column align-items-start" v-for="entry in cart" v-bind:key="entry.id">
          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
            <b class="mr-2">{{ entry.amount }}x {{ entry.title }}</b>
            <span class="mr-2">{{ entry.price | toCurrency }}</span>

            <b-button variant="outline-danger" size="sm" @click="removeFromCart(entry.id)">
              <b-icon-x></b-icon-x>
            </b-button>
          </div>
          <p v-for="option in entry.options" v-bind:key="option.id">{{ option.name }}</p>
        </b-list-group-item>

        <b-list-group-item class="flex-column align-items-start" active-class="outline-success">
          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
            <b class="mr-2">
              Speisen
              <template v-if="minimumOrderValue > 0 && calculateDeliverySurcharge() === 0">
                (Mindestbestellwert: {{ minimumOrderValue | toCurrency }})
              </template>
            </b>
            <span class="mr-2">{{ cartSum() | toCurrency }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0" v-if="calculateDiscountAmount() > 0">
            <b class="mr-2">Rabatt</b>
            <span class="mr-2">{{ calculateDiscountAmount() | toCurrency }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0" v-if="deliveryFee > 0">
            <b class="mr-2">Liefergebühr</b>
            <span class="mr-2">{{ deliveryFee | toCurrency }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0" v-if="calculateDeliverySurcharge() > 0">
            <b class="mr-2">Aufschlag (Mindestbestellwert: {{ minimumOrderValue | toCurrency }})</b>
            <span class="mr-2">{{ calculateDeliverySurcharge() | toCurrency }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0" v-if="paymentMethod.surcharge > 0">
            <b class="mr-2">{{ paymentMethod.surcharge }}% {{ paymentMethod.friendlyName }} Aufschlag</b>
            <span class="mr-2">{{ calculatePaymentFee() | toCurrency }}</span>
          </div>
        </b-list-group-item>


        <b-list-group-item class="flex-column align-items-start" active>
          <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
            <b class="mr-2">Gesamt</b>
            <span class="mr-2">{{ calculateSum() | toCurrency }}</span>
          </div>
        </b-list-group-item>
      </b-list-group>

      <b-form class="mb-3">
        <b-form-group label="Kommentar"  v-bind:description="`(${comment != null ? comment.length : 0}/255) Kommentar zu deiner Bestellung`">
          <b-textarea v-model="comment" maxlength="255"></b-textarea>
        </b-form-group>
      </b-form>

      <span class="ml-auto mr-auto">
        <b-button v-b-toggle.payment-collapse.overview-collapse variant="outline-primary">Zurück</b-button>
        <b-button class="float-right" variant="outline-success" @click="order()" :disabled="!orderPossible">Bestellen</b-button>
      </span>
    </b-collapse>

  </div>
</template>
<script>
export default {
  data() {
    return {
      address: {
        companyName: '',
        firstname: '',
        lastname: '',
        street: '',
        houseNr: '',
        city: '',
        phoneNumber: ''
      },
      comment: null,
      type: null,
      paymentMethod: '',
      paymentMethods: {},
      cart: this.$store.getters.cart,
      addresses: [],
      deliveryFee: 0,
      minimumOrderValue: 0,
      deliveryAreas: [],
      availableCities: [],
      activeDiscounts: []
    }
  },
  async created() {
    if (this.cart.length === 0) {
      this.$router.push('/');
    }

    if (this.$store.getters.loggedIn) {
      this.loadAddresses();
      this.address.firstname = this.$store.getters.user.firstname;
      this.address.lastname = this.$store.getters.user.lastname;
    }

    let response = await this.$http.get('/api/tenant/payment');
    this.paymentMethods = response.data;

    response = await this.$http.get('/api/tenant/delivery');
    this.deliveryAreas = response.data;

    for (const area of this.deliveryAreas) {
      for (const city of area.cities) {
        if (this.availableCities.indexOf(city.city) === -1)
          this.availableCities.push(city.city);
      }
    }

    this.$http.get('/api/discount/active').then(response => { this.activeDiscounts = response.data; });

    this.setTitle("Bestellen");
    this.$loading = false;
  },
  methods: {
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    encodeByteArrayToUrlBase64(byteArray) {
      const base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(byteArray)));
      return base64.replace(/={1,2}$/, '')
          .replace(/\+/g, '-')
          .replace(/\//g, '_');
    },
    loadAddresses() {
      this.$http.get('/api/user/addresses').then(response => {
        this.addresses = response.data;
      });
    },
    chunk(arr, chunkSize) {
      if (chunkSize <= 0) throw "Invalid chunk size";
      const R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
      return R;
    },
    applySavedAddress(address) {
      this.address = address;
    },
    async order() {
      /*if('serviceWorker' in navigator) {
        if (Notification.permission === "default") {
          let self = this;
          let permission = await Notification.requestPermission();
          if (permission === "granted") {
            let serviceWorkerRegistration = await navigator.serviceWorker.register('service-worker.js');
            let pushSubscription = await serviceWorkerRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: self.urlBase64ToUint8Array(window.$tenant.publicKey)
            });

            let subscriptionConfig = {
              endpoint: pushSubscription.endpoint,
              key: self.encodeByteArrayToUrlBase64(pushSubscription.getKey('p256dh')),
              auth: self.encodeByteArrayToUrlBase64(pushSubscription.getKey('auth'))
            };

            this.$store.commit('SET_NOTIFICATION_SETTINGS', subscriptionConfig.endpoint, subscriptionConfig.key, subscriptionConfig.auth);

            if (this.$store.getters.loggedIn) {
              await self.$http.put('/api/user/addPushSubscription', subscriptionConfig);
            }
          }
        }
      }*/

      this.$loading = true;
      this.$http.put('/api/order', {
        meals: this.cart.map(cartEntry => cartEntry.configuration),
        comment: this.comment,
        address: this.address,
        paymentMethod: this.paymentMethod,
        type: this.type,
        notificationConfig: this.$store.getters.notificationConfig
      }).then(response => {
        this.$store.dispatch('clear');
        this.$store.commit("SET_CHECKOUT_ORDER", response.data.orderUUID);
        if (response.data.paymentRedirect) {
          window.location = response.data.redirectURL;
        } else {
          this.$router.push('/paymentreturn');
        }
      }); // TODO handle error?
    },
    cartSum() {
      return this.cart.reduce((sum, current) => sum + current.price, 0);
    },
    calculateDiscountAmount() {
      if (this.type == null) return 0;

      let totalDiscount = 0;

      let fixedDiscountUsage = {};
      this.activeDiscounts.forEach(discount => {
        fixedDiscountUsage[discount] = discount.value * 10;
      });

      this.cart.forEach(cartEntry => {
        this.activeDiscounts.forEach(discount => {
          if (discount.orderTypes.indexOf(this.type) === -1) return;
          if (discount.mealTypes.indexOf(cartEntry.type) === -1) return;

          let value;
          if (discount.type === 'FIX') {
            let remainingValue = fixedDiscountUsage[discount];

            if (cartEntry.price > remainingValue) {
              value = remainingValue;
            } else {
              value = cartEntry.price;
            }
            remainingValue -= value;

            fixedDiscountUsage.put(discount, remainingValue);

          } else { // Percent
            value = Math.round(cartEntry.price * discount.value / 100);
          }

          totalDiscount += value;
        });
      });

      return Math.min(this.cartSum(), totalDiscount);
    },
    calculateSum() {
      return this.cartSum() - this.calculateDiscountAmount() + this.deliveryFee + this.calculatePaymentFee() + this.calculateDeliverySurcharge();
    },
    calculatePaymentFee() {
      let itemsAndDeliveryFee = this.cart.reduce((sum, current) => sum + current.price, 0) + this.deliveryFee;
      return itemsAndDeliveryFee * (this.paymentMethod !== '' ? Math.round(this.paymentMethods[this.paymentMethod].surcharge / 100) : 0);
    },
    setDeliveryFee() {
      let deliveryArea = this.deliveryAreas.find(area => area.cities.find(city => city.city === this.address.city));
      this.deliveryFee = deliveryArea.deliveryFee;
      this.minimumOrderValue = deliveryArea.minimumOrderValue;
    },
    calculateDeliverySurcharge() {
      let diff = this.minimumOrderValue - this.cartSum();
      return diff > 0 ? diff : 0;
    },
    removeFromCart(entryId) {
      this.$store.dispatch('remove', {entryId});
    },
    async selectPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    validatePhoneNumber(number) {
        const regex = /0\d{1,3}\d+/g;
        return regex.test(number);
    },
    validateNotEmpty(str) {
        return str !== '';
    },
  },
  watch: {
    cart(value) {
      if (value.length === 0) this.$router.push('/');
    }
  },
  computed: {
    nameCompleted() {
      return this.validatePhoneNumber(this.address.phoneNumber) &&
          this.validateNotEmpty(this.address.firstname) &&
          this.validateNotEmpty(this.address.lastname);
    },
    addressCompleted() {
      if (!this.validatePhoneNumber(this.address.phoneNumber) ||
        !this.validateNotEmpty(this.address.firstname) ||
        !this.validateNotEmpty(this.address.lastname) ||
        !this.validateNotEmpty(this.address.street) ||
        !this.validateNotEmpty(this.address.houseNr) ||
        !this.validateNotEmpty(this.address.city)) {
        return;
      }

      return this.deliveryAreas.find(area => area.cities.find(city => city.city === this.address.city)) !== undefined;
    },
    orderPossible() {
      return this.isOpen(new Date(), this.type);
    },
  }
}
</script>