<template>
  <div>
    <h2>Wir verarbeiten Ihre Bestellung...</h2>
    <hr>
  </div>
</template>

<style>

</style>

<script>
export default {
  beforeCreate() {
    let orderUUID = this.$store.getters.checkoutOrder;
    if (orderUUID === '') {
      this.$router.push('/');
      return;
    }

    this.$http.get('/api/order/capture/' + orderUUID).then(() => {
      this.$store.commit('ADD_ORDER', orderUUID);
    });
    this.$store.commit("REMOVE_CHECKOUT_ORDER");
    this.$router.push('/');
  }
}
</script>