<template>
  <div>
    <template v-if="showTitle">
      <h1>{{ title }}</h1>
      <hr>
    </template>
    <DynamicRenderer v-if="render" :body="content"/>
  </div>
</template>

<script>
import DynamicRenderer from "@/components/DynamicComponent";

async function update(self, pageId, done) {
  self.$loading = true;
  let response = await self.$http.get("/api/page/" + pageId);
  self.title = response.data.title;
  self.content = response.data.content.replaceAll('[OpeningHours]', '<OpeningHoursComponent/>');
  self.showTitle = response.data.showTitle;

  self.setTitle(self.title);
  await done();
  self.$loading = false;
}

export default {
  components: {DynamicRenderer},
  async beforeCreate() {
    let pageId = this.$route.params.id;
    await update(this, pageId, () => {});
  },
  async beforeRouteUpdate(to, from, next) {
    let pageId = to.params.id;
    await update(this, pageId, next);

    this.render = false;
    this.$nextTick(() => this.render = true);
  },
  data() {
    return {
      render: true,
      title: '',
      content: '',
      showTitle: false,
    }
  },
}
</script>
