<template>
  <b-modal v-bind:id="id" v-bind:title="mode === mode.EDIT ? 'Adresse bearbeiten' : 'Adresse anlegen'" size="lg"
           ok-variant="outline-success" cancel-variant="outline-danger" ok-title="Speichern" cancel-title="Abbrechen" @ok="save()">
    <b-form>
      <b-form-group label="Firmenname">
        <b-form-input type="text" placeholder="Firmenname" required v-model="address.companyName"></b-form-input>
      </b-form-group>

      <b-form-group label="Vor- und Nachname">
        <b-row>
          <b-col md="6">
            <b-form-input placeholder="Vorname" required v-model="address.firstname"></b-form-input>
          </b-col>
          <b-col md="6">
            <b-form-input placeholder="Nachname" required v-model="address.lastname"></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Telefonnummer">
        <b-row>
          <b-col md="6">
            <b-form-input placeholder="Telefonnummer" required v-model="address.phoneNumber"></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Straße & Hausnummer">
        <b-row>
          <b-col md="8">
            <b-form-input placeholder="Straße" required v-model="address.street"></b-form-input>
          </b-col>
          <b-col md="4">
            <b-form-input placeholder="Hausnummer" required v-model="address.houseNr"></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Ort">
        <b-form-input placeholder="Ort" required v-model="address.city"></b-form-input>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
const mode = {
  EDIT: 1,
  NEW: 2
};
export default {
  props: ['id'],
  data() {
    return {
      mode: 0,
      address: {},
      ref: {},
      saveCallback: null
    }
  },
  methods: {
    updateRef(address) {
      this.ref.companyName = address.companyName;
      this.ref.firstname = address.firstname;
      this.ref.lastname = address.lastname;
      this.ref.street = address.street;
      this.ref.houseNr = address.houseNr;
      this.ref.zip = address.zip;
      this.ref.city = address.city;
      this.ref.phoneNumber = address.phoneNumber;

      if (this.saveCallback != null) {
        this.saveCallback(address);
      }
    },
    save() {
      if (this.mode === mode.NEW) {
        this.$http.put('/api/user/addresses', this.address).then(response => this.updateRef(response.data));
      }  else if (this.mode === mode.EDIT) {
        this.$http.post('/api/user/addresses', this.address).then(response => this.updateRef(response.data));
      }
    },
    setAddress(address) {
      if (address.uuid) {
        this.mode = mode.EDIT;
      } else {
        this.mode = mode.NEW;
      }
      this.ref = address;
      this.address = Object.assign({}, address);
    },
    show(saveCallback) {
      this.saveCallback = saveCallback;
      this.$bvModal.show(this.id);
    }
  }
}
</script>