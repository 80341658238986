<template>
  <div class="accordion" role="tablist">
    <div v-for="(typeName, n) of Object.keys(types)" v-bind:key="typeName">
      <b-collapse v-bind:visible="!show[n]">
        <b-button block v-b-toggle="'type-' + typeName" variant="link"
                  v-bind:class="types[typeName].openRightNow ? 'text-success' : 'text-danger'">
          {{ types[typeName].openHeading }}
        </b-button>
      </b-collapse>

      <b-collapse v-model="show[n]" v-bind:id="'type-' + typeName" accordion="my-accordion" class="text-success"
                  role="tabpanel">
        <b-button block v-b-toggle="'type-' + typeName" variant="link" class="text-muted">
          {{ types[typeName].heading }}
        </b-button>
        <b-table class="mt-2" :items="types[typeName].weekDays" :fields="fields"
                 :tbody-tr-class="rowClass"></b-table>
      </b-collapse>

    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      show: [],
      types: {},

      fields: [
        {key: 'weekday', label: 'Wochentag'},
        {key: 'times', label: 'Öffnungszeiten'}
      ],
    }
  },
  beforeMount() {
    let openingHours = window.$tenant.openingHours;

    let messages = {
      'GENERAL': {
        'openHeading': function (openRightNow, currentTimeFrame) {
          if (openRightNow) return 'geöffnet ' + (currentTimeFrame ? ' - ' + currentTimeFrame : '');
          return 'geschlossen' + (currentTimeFrame ? ' - ' + currentTimeFrame : '');

        },
        'heading': 'Öffnungszeiten'
      },
      'PICK_UP': {
        'openHeading': 'Abholung',
        'heading': 'Abholzeiten'
      },
      'DELIVERY': {
        'openHeading': 'Lieferung',
        'heading': 'Lieferzeiten'
      },
      'weekday': {
        'MONDAY': 'Montag',
        'TUESDAY': 'Dienstag',
        'WEDNESDAY': 'Mittwoch',
        'THURSDAY': 'Donnerstag',
        'FRIDAY': 'Freitag',
        'SATURDAY': 'Samstag',
        'SUNDAY': 'Sonntag',
        'HOLIDAY': 'Feiertag'
      }
    }

    let pad = n => n < 10 ? '0' + n : '' + n;

    let config = {
      'GENERAL': {
        'MONDAY': [],
        'TUESDAY': [],
        'WEDNESDAY': [],
        'THURSDAY': [],
        'FRIDAY': [],
        'SATURDAY': [],
        'SUNDAY': [],
        'HOLIDAY': []
      },
      'PICK_UP': {
        'MONDAY': [],
        'TUESDAY': [],
        'WEDNESDAY': [],
        'THURSDAY': [],
        'FRIDAY': [],
        'SATURDAY': [],
        'SUNDAY': [],
        'HOLIDAY': []
      },
      'DELIVERY': {
        'MONDAY': [],
        'TUESDAY': [],
        'WEDNESDAY': [],
        'THURSDAY': [],
        'FRIDAY': [],
        'SATURDAY': [],
        'SUNDAY': [],
        'HOLIDAY': []
      }
    };


    for (let openingHour of openingHours) {
      config[openingHour.type][openingHour.weekDay].push(openingHour);
    }

    for (let typeName of Object.keys(config)) {
      if (!Object.keys(this.types).includes(typeName)) {
        let date = new Date();

        let openingHoursOfDay = Object.values(config[typeName])[date.getDay() - 1 < 0 ? 6 : date.getDay() - 1]
            .sort((o1, o2) => o1.openHour > o2.openHour);

        let currentTimeFrame = openingHoursOfDay.filter(entry =>
            date >= new Date().setHours(entry.openHour, entry.openMinute, 0) &&
            date <= new Date().setHours(entry.closingHour, entry.closingMinute, 0)
        );

        let openRightNow = currentTimeFrame.length !== 0;

        currentTimeFrame = openingHoursOfDay.map(openingHour => pad(openingHour.openHour) + ':' + pad(openingHour.openMinute) + ' - ' + pad(openingHour.closingHour) + ':' + pad(openingHour.closingMinute)).join(', ');

        let typeConfig = {
          openRightNow: openRightNow,
          'heading': messages[typeName].heading,
          'openHeading': (messages[typeName].openHeading instanceof Function) ? messages[typeName].openHeading(openRightNow, currentTimeFrame) : messages[typeName].openHeading  + (currentTimeFrame ? ' - ' + currentTimeFrame : ''),
          weekDays: [],
        }
        Object.assign(this.types, {[typeName]: typeConfig});
        this.show.push(false);
      }


      for (let weekDay of Object.keys(config[typeName])) {

        let times = config[typeName][weekDay]
            .sort((o1, o2) => o1.openHour > o2.openHour)
            .map(openingHour => pad(openingHour.openHour) + ':' + pad(openingHour.openMinute) + ' - ' + pad(openingHour.closingHour) + ':' + pad(openingHour.closingMinute))
            .join(', ');

        let opened = true;

        if (times === '') {
          times = 'geschlossen';
          opened = false;
        }

        this.types[typeName].weekDays.push({
          weekday: messages.weekday[weekDay],
          times: times,
          opened: opened
        });

      }
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.opened === false) return 'table-danger'
    }
  }
}
</script>