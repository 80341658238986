<template>
  <div id="app">
    <div id="busy-indicator" v-if="showBusyIndicator">
      <sync-loader id="loader" color="#fff" :loading="true"></sync-loader>
    </div>
    <b-navbar toggleable="lg" type="dark" variant="dark" sticky>
      <b-navbar-brand href="/" class="pt-0 pb-0">
        <img v-bind:alt="tenantName" v-bind:src="logoURL" height="40px" v-if="logoAvailable">
        <template v-if="!logoAvailable">{{ tenantName }}</template>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="ml-auto mr-3"></b-navbar-toggle>

      <a id="nav-cart" class="btn btn-secondary d-block d-sm-block d-md-block d-lg-none" tabindex="0"
                style="background-color: transparent; color: rgba(255, 255, 255, 0.5); cursor: pointer;">
        <b-icon-cart2 style="width: 1.5em; height: 1.5em;"></b-icon-cart2>
        <b-badge variant="dark" style="margin-left: -25%; background: transparent; vertical-align: top;">
          {{ cart.length > 0 ? cart.reduce((sum, current) => sum + current.amount, 0) : "" }}
        </b-badge>
      </a>

      <b-popover target="nav-cart" triggers="focus" title="Bestellung" placement="bottom">
        <b-list-group flush class="mb-3">

          <b-list-group-item class="flex-column align-items-start" v-for="entry in cart" v-bind:key="entry.id">
            <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
              <b class="mr-2">{{ entry.amount }}x {{ entry.title }}</b>
              <span class="mr-2">{{ entry.price | toCurrency }}</span>

              <b-button variant="outline-danger" size="sm" @click="$refs.order.focus(); removeFromCart(entry.id)">
                <b-icon-x></b-icon-x>
              </b-button>
            </div>
            <p v-for="option in entry.options" v-bind:key="option.id">{{ option.name }}</p>
          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start" active>
            <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
              <b class="mr-2">Gesamt</b>
              <span class="mr-2">{{ calculateSum() | toCurrency }}</span>
            </div>
          </b-list-group-item>
        </b-list-group>


        <b-button variant="outline-primary" ref="order"
                  @click="$router.push('/checkout'); $root.$emit('bv::hide::popover')">Bestellen
        </b-button>
        <b-button variant="outline-danger" class="float-right" @click="clearCart();">
          <b-icon-trash></b-icon-trash>
        </b-button>

      </b-popover>


      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="flex-wrap">
          <router-link v-for="navItem in topNav" v-bind:key="navItem.target" v-model="topNav" tag="li"
                       class="nav-item" :to="{name: navItem.type.toLowerCase(), params: {id: navItem.target}}"><a
              class="nav-link">{{ navItem.name }}</a></router-link>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <template v-if="!this.$store.getters.loggedIn">
            <router-link tag="li" class="nav-item" to="/login">
              <a class="nav-link">Login</a>
            </router-link>


            <router-link tag="li" class="nav-item" to="/register">
              <a class="nav-link">Registrieren</a>
            </router-link>
          </template>
          <template v-else>
            <router-link tag="li" class="nav-item" to="/profile">
              <a class="nav-link">Profil</a>
            </router-link>

            <li class="nav-item">
              <a class="nav-link" href="#" @click="logout()">Logout</a>
            </li>
          </template>
        </b-navbar-nav>
      </b-collapse>


      <a id="nav-cart2" class="btn btn-secondary d-none d-lg-none d-lg-none d-lg-block ml-2" tabindex="0"
                style="background-color: transparent; color: rgba(255, 255, 255, 0.5); cursor: pointer;">
        <b-icon-cart2 style="width: 1.5em; height: 1.5em;"></b-icon-cart2>
        <b-badge variant="dark" style="margin-left: -25%; background: transparent; vertical-align: top;">
          {{ cart.length > 0 ? cart.length : "" }}
        </b-badge>
      </a>

      <b-popover target="nav-cart2" triggers="focus" title="Bestellung" placement="bottom">
        <b-list-group flush class="mb-3">

          <b-list-group-item class="flex-column align-items-start" v-for="entry in cart" v-bind:key="entry.id">
            <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
              <b class="mr-2">{{ entry.amount }}x {{ entry.title }}</b>
              <span class="mr-2">{{ entry.price | toCurrency }}</span>

              <b-button variant="outline-danger" size="sm" @click="$refs.order2.focus(); removeFromCart(entry.id)">
                <b-icon-x></b-icon-x>
              </b-button>
            </div>
            <p v-for="option in entry.options" v-bind:key="option.id">{{ option.name }}</p>
          </b-list-group-item>

          <b-list-group-item class="flex-column align-items-start" active>
            <div class="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
              <b class="mr-2">Gesamt</b>
              <span class="mr-2">{{ calculateSum() | toCurrency }}</span>
            </div>
          </b-list-group-item>
        </b-list-group>


        <b-button variant="outline-primary" ref="order2"
                  @click="$router.push('/checkout'); $root.$emit('bv::hide::popover')">Bestellen
        </b-button>
        <b-button variant="outline-danger" class="float-right" @click="clearCart();">
          <b-icon-trash></b-icon-trash>
        </b-button>

      </b-popover>
    </b-navbar>

    <b-container class="mt-2 mb-2">
      <b-card v-if="!isOpenDeliveryPickUp(new Date())" border-variant="danger">
        <b-card-sub-title>Wir haben geschlossen!</b-card-sub-title>
      </b-card>

      <template v-for="order in orders">
        <b-card v-if="order.state === 'IN_TRANSIT' && order.type === 'PICK_UP'" border-variant="success"
                v-bind:key="order.uuid">
          <b-card-sub-title sub-title-text-variant="success">
            Deine Bestellung ist abholbereit!
          </b-card-sub-title>
        </b-card>

        <b-card v-if="order.state === 'IN_TRANSIT' && order.type === 'DELIVERY'" border-variant="success"
                v-bind:key="order.uuid">
          <b-card-sub-title sub-title-text-variant="success">
            Deine Bestellung ist unterwegs zu dir!
          </b-card-sub-title>
        </b-card>

        <b-card v-if="order.state === 'IN_PREPARATION'" border-variant="warning" class="mt-1" v-bind:key="order.uuid">
          <b-card-sub-title sub-title-text-variant="warning">
            <template v-if="order.type === 'PICK_UP'">
              Deine Bestellung wird zubereitet und ist ca. {{ order.approximateDoneAt | formatTime }} Uhr abholbereit!<br/>
            </template>
            <template v-if="order.type === 'DELIVERY'">
              Deine Bestellung wird zubereitet und ca. {{ order.approximateDoneAt | formatTime }} Uhr geliefert!<br/>
            </template>
          </b-card-sub-title>
        </b-card>

        <b-card v-if="order.state === 'RESERVED'" border-variant="dark" class="mt-1" v-bind:key="order.uuid">
          <b-card-sub-title sub-title-text-variant="dark">
            Wir haben dein Bestellung erhalten!
          </b-card-sub-title>
        </b-card>
      </template>

    </b-container>
    <b-container style="margin-bottom: 75px">
      <router-view/>
    </b-container>


    <b-navbar toggleable="lg" type="dark" variant="dark" fixed="bottom">
      <b-navbar-toggle target="footer-nav" class="ml-auto">
        <template v-slot:default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-down"></b-icon>
          <b-icon v-else icon="chevron-bar-up"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="footer-nav" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-for="navItem in bottomNav" v-bind:key="navItem.target" v-model="bottomNav" tag="li"
                      class="nav-item" :to="{name: navItem.type.toLowerCase(), params: {id: navItem.target}}">
            {{ navItem.name }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<style>
#busy-indicator {
  z-index: 1300;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  -ms-flex-direction: row;
  -ms-flex-align: center;
  display: -webkit-flex;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);

  -webkit-animation: fadein 0.7s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.7s; /* Firefox < 16 */
  -ms-animation: fadein 0.7s; /* Internet Explorer */
  -o-animation: fadein 0.7s; /* Opera < 12.1 */
  animation: fadein 0.7s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#busy-indicator > #loader {
  display: block;
  margin: auto;
}
</style>
<script>
export default {
  beforeCreate() {
    this.topNav = [];
    this.bottomNav = [];

    this.$http.get('/api/navigation')
        .then(response => {
          this.topNav = response.data.navigation.TOP;
          this.bottomNav = response.data.navigation.BOTTOM;
        });

    if (this.$store.state.token !== '') {
      let payload = JSON.parse(window.atob(this.$store.state.token.split(".")[1]));

      if (payload.exp < Math.round((new Date()). getTime() / 1000)) {
        this.$store.dispatch('logout');
      } // TODO get a fresh token if the old one is still valid
    }
  },
  watch: {
    '$loading'(value) {
      this.showBusyIndicator = value;
    },
    '$route'(from, to) {
      if (from.name !== to.name) {
        this.$loading = true;
      }
    }
  },
  created() {
    this.updateOrder();
    setInterval(this.updateOrder, 5000);
  },
  data() {
    return {
      topNav: this.topNav,
      bottomNav: this.bottomNav,
      cart: this.$store.getters.cart,
      showBusyIndicator: this.$loading,
      orders: []
    }
  },
  methods: {
    async updateOrder() {
      let orders = this.$store.getters.orders;
      let newOrders = [];

      for (const orderUUID of orders) {
        try {
          let response = await this.$http.get('/api/order/' + orderUUID);
          if (response.data.state === 'DONE' || response.data.state === 'CANCELLED') {
            this.$store.commit('REMOVE_ORDER', orderUUID);
          } else {
            newOrders.push(response.data);
          }
        } catch (err) {
          this.$store.commit('REMOVE_ORDER', orderUUID);
        }
      }

      this.orders = newOrders;
    },
    removeFromCart(entryId) {
      this.$store.dispatch('remove', {entryId});
    },
    clearCart() {
      this.$store.dispatch('clear');
    },
    calculateSum() {
      return this.cart.reduce((sum, current) => sum + current.price, 0);
    },
    logout() {
      this.$store.dispatch('logout');
      window.location.reload();
    }
  },
  computed: {
    logoURL() {
      return '/static/' + window.$tenant.logo;
    },
    logoAvailable() {
      return window.$tenant.logo != null;
    },
    tenantName() {
      return window.$tenant.name;
    }
  }
}
</script>