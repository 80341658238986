import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import axios from 'axios'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import router from './router'
import createPersistedState from 'vuex-persistedstate'
import titleMixin from './mixins/titleMixin'
import openingHoursMixin from "./mixins/openingHoursMixin"
import { VueSpinners } from '@saeris/vue-spinners'
import { format } from 'date-fns'

Vue.config.productionTip = false;


Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);
Vue.mixin(titleMixin);
Vue.mixin(openingHoursMixin);
Vue.use(VueSpinners);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);

const cart = {
    state: () => ({ content: [], _idCounter: 0 }),
    mutations: {
        SET_ID_COUNTER: (state, counter) => {
            state._idCounter = counter;
        },
        CLEAR_CART: (state) => {
            state.content.splice(0, state.content.length);
        },
        REMOVE_MEAL: (state, entryId) => {
            let entry = state.content.find(entry => entry.id === entryId);
            state.content.splice(state.content.indexOf(entry), 1);
        },
        ADD_CART_ITEM: (state, meal) => {
            state.content.push(meal);
        },
    },
    getters: {
        _idCounter: state => {
            return state._idCounter;
        },
        cart: state => {
            return state.content;
        }
    },
    actions: {
        add: ({ commit, state }, { cartItem }) => {
            cartItem.id = state._idCounter;
            commit('SET_ID_COUNTER', state._idCounter + 1);
            commit('ADD_CART_ITEM', cartItem);
        },
        remove: ({ commit }, { entryId }) => {
            commit('REMOVE_MEAL', entryId);
        },
        clear: ({ commit }) => {
            commit('SET_ID_COUNTER', 0);
            commit('CLEAR_CART');
        },
    }
};


const store = new Vuex.Store({
    plugins: [createPersistedState()],
    modules: [
        cart
    ],
    state: {
        token: '',
        user: {},
        orders: [],
        checkoutOrder: '',
        notificationEndpoint: '',
        notificationKey: '',
        notificationAuth: ''
    },
    getters: {
        loggedIn: state => {
            return state.token;
        },
        user: state => {
            return state.user;
        },
        orders: state => {
            return state.orders;
        },
        checkoutOrder: state => {
            return state.checkoutOrder;
        },
        notificationConfig: state => {
            return {
                endpoint: state.notificationEndpoint,
                key: state.notificationKey,
                auth: state.notificationAuth
            };
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        ADD_ORDER: (state, newOrder) => {
            state.orders.push(newOrder);
        },
        REMOVE_ORDER: (state, oldOrder) => {
            state.orders = state.orders.filter(order => order !== oldOrder);
        },
        SET_CHECKOUT_ORDER: (state, checkoutOrder) => {
            state.checkoutOrder = checkoutOrder;
        },
        REMOVE_CHECKOUT_ORDER: state => {
            state.checkoutOrder = '';
        },
        SET_NOTIFICATION_SETTINGS: (state, endpoint, key, auth) => {
            state.notificationEndpoint = endpoint;
            state.notificationKey = key;
            state.notificationAuth = auth;
        }

    },
    actions: {
        login: ({ commit }, { token, user }) => {
            commit('SET_TOKEN', token);
            commit('SET_USER', user);

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        logout: ({ commit }) => {
            commit('SET_TOKEN', '');

            axios.defaults.headers.common['Authorization'] = '';
        }
    }
});
axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

Vue.filter('toCurrency', function(value) {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat('de-de', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value / 100);
});

Vue.filter('formatDate', function(value) {
    let date = Date.parse(value);
    return format(date, 'dd.MM.yyyy');
});

Vue.filter('formatTime', function(value) {
    let date = Date.parse(value);
    return format(date, 'HH:mm');
});

Vue.use(cart);

const loading = Vue.observable({ loading: {} });
Object.defineProperty(Vue.prototype, '$loading', {
    get() { return loading.loading; },
    set(value) { loading.loading = value; }
});


Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

if (store.getters.notificationConfig.endpoint !== '' && Notification.permission !== 'granted') {
    store.commit('SET_NOTIFICATION_SETTINGS', '', '', '');
}


axios.get('/api/tenant').then(response => {
    window.$tenant = response.data;
    new Vue({
        router: router,
        render: h => h(App),
        store: store
    }).$mount('#app');
});
