<script>
import VueWithCompiler from "vue/dist/vue.esm";
import OpeningHoursComponent from "@/components/OpeningHoursComponent";

export default {
  components: {OpeningHoursComponent},
  props: {
    body: {
      type: String,
      default: "",
    },
  },
  data() {
    return { templateRender: undefined };
  },
  watch: {
    body() {
      this.updateRender();
    },
  },
  created() {
    this.updateRender();
  },
  methods: {
    updateRender() {
      const compiled = VueWithCompiler.compile('<div>' + this.body + '</div>');
      this.templateRender = compiled.render;
      this.$options.staticRenderFns = [];
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction);
      }
    },
  },
  render() {
    return this.templateRender();
  },
};
</script>