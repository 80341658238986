<template>
  <b-row>
    <b-card title="Registrieren" sub-title="Herzlichen willkommen!" class="ml-auto mr-auto">
      <b-alert variant="danger" v-model="error" dismissible>Registrierung ist fehlgeschlagen: {{ msg }}</b-alert>
      <b-form @submit.prevent="register">
        <b-form-group>
          <b-form-input type="text" required placeholder="Vorname" v-model="firstname"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input type="text" required placeholder="Nachname" v-model="lastname"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input type="email" required placeholder="E-Mail" v-model="username"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input type="password" required min="8" placeholder="Passwort" v-model="password"></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="outline-success">Registrieren</b-button>
      </b-form>
    </b-card>
  </b-row>
</template>

<style>

</style>

<script>
export default {
  name: 'Register',
  title: 'Registrieren',
  data() {
    return {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      msg: '',
      error: false
    }
  },
  created() {
    if (this.$store.getters.loggedIn) {
      this.$router.push('/');
    }
    this.$loading = false;
  },
  methods: {
    register() {
      this.$http.post('/api/user/register', {
        firstname: this.firstname,
        lastname: this.lastname,
        username: this.username,
        password: this.password
      }).then(() => {
        this.$router.push("/login");
        // store in vuex store
      }).catch(error => {
        if (error.response.status === 400) { // 401 Unauthenticated
          this.msg = error.response.data.message;
          this.error = true;
        }
      });

    }
  }
}
</script>
