import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import Page from '../views/Page'
import Category from '../views/Category'
import Register from "@/views/Register";
import Profile from "@/views/Profile";
import Checkout from "@/views/Checkout";
import PaymentReturn from "@/views/PaymentReturn";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: Checkout
    },
    {
        path: '/paymentreturn',
        name: 'paymentReturn',
        component: PaymentReturn
    },
    {
        path: '/c/:id',
        name: 'category',
        component: Category
    },
    {
        path: '/:id',
        name: 'page',
        component: Page
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
