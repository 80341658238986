export default {
    methods: {
        isOpen(date, type) {
            let found = window.$tenant.openingHours.find(entry =>
                entry.type === type &&
                entry.weekDay === date.toLocaleString('en-us', {weekday: 'long'}).toUpperCase() &&
                date >= new Date().setHours(entry.openHour, entry.openMinute, 0) &&
                date <= new Date().setHours(entry.closingHour, entry.closingMinute, 0)
            );

            return found !== undefined;
        },
        isOpenDeliveryPickUp(date) {
            return this.isOpen(date, 'PICK_UP') || this.isOpen(date, 'DELIVERY');
        }
    }
}