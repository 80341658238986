<template>
    <b-modal v-bind:id="id" hide-header @ok="confirm()"
             ok-variant="outline-danger" cancel-variant="outline-primary" ok-title="Löschen" cancel-title="Abbrechen">
      <h1>Wirklich löschen?</h1>
    </b-modal>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      confirmCallback: null
    }
  },
  methods: {
    show(confirmCallback) {
      this.confirmCallback = confirmCallback;
      this.$bvModal.show(this.id);
    },
    confirm() {
      if (this.confirmCallback != null) {
        this.confirmCallback();
      }
    }
  }
}
</script>